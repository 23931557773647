import { Container, NavbarText } from "./styles";

import React from "react";

export const Navbar = () => {
  return (
    <Container>
      <NavbarText>Quiz App</NavbarText>
    </Container>
  );
};
